import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import continisTheme from "@continis/theme";
import Sortable from "sortablejs";
import TomSelect from "tom-select";

Alpine.plugin(collapse);
Alpine.plugin(continisTheme);

window.htmx = require('htmx.org');
window.Alpine = Alpine;
window.Sortable = Sortable;
window.TomSelect = TomSelect;

Alpine.start();
